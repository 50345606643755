<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>顧客情報登録</template>
              <template v-slot:body>
                <CustomerForm ref="form"></CustomerForm>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <router-link class="btn btn-white router-link-active" :to="{ name: 'EventUser' }">キャンセル</router-link>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-main" :handle-submit="register" button-text="登録する" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import nav from '@/mixins/nav/user';
import CustomerForm from './Form';

export default {
  name: 'EventUserRegister',
  data: function() {
    return {
      pageName: '顧客管理',
    };
  },
  computed: {
    subdomain() {
      return this.$route.params.subdomain;
    },
  },
  mixins: [nav],
  components: {
    CustomerForm,
  },
  methods: {
    register: function() {
      this.$refs.form.handleSubmit()
    },
  },
};
</script>
